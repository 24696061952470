import Axios from 'axios';
import React from 'react';
import { Alert, CloseIcon, Dialog } from '@fluentui/react-northstar';
import { GetSolutionDTO } from '../../models/Administration/ModuleController/getSolutionDTO';

interface IModuleDeleteDialogProps {
  onClose?: any;
  module: GetSolutionDTO;
}

interface IModuleDeleteDialogState {
  error: string | null;
  loading: boolean;
}

export class ModuleDeleteDialog extends React.Component<IModuleDeleteDialogProps, IModuleDeleteDialogState> {

  public state: IModuleDeleteDialogState = {
    error: null,
    loading: false,
  }

  public submitForm = async () => {
    try {
      this.setState({ loading: true, error: null });
      await Axios.delete(process.env.REACT_APP_API_BASE + '/api/admin/modules/' + this.props.module?.id);
      this.setState({ loading: false });
      this.props.onClose(true);
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.errorCode) {
        this.setState({ loading: false, error: error.response.data.errorCode });
      } else {
        this.setState({ loading: false, error: 'Failed to delete the module!' });
      }
    }
  }

  render() {

    const content = (
      <React.Fragment>
        {!this.state.loading && this.state.error ? <Alert danger={true} content={this.state.error} /> : null}
        <div style={{ marginTop: '6px' }}>Do you really want to remove the module <strong>"{this.props.module.name}"</strong>? The module can only be removed if it is not used by any tenants!</div>
      </React.Fragment>
    );

    return (
      <Dialog
        open={true}
        styles={{ width: '420px' }}
        closeOnOutsideClick={false}
        onCancel={() => this.props.onClose && this.props.onClose(false)}
        onConfirm={this.submitForm}
        cancelButton={{ content: 'Cancel', disabled: this.state.loading }}
        confirmButton={{ content: 'Confirm', primary: true, disabled: this.state.loading, loading: this.state.loading }}
        content={content}
        header="Delete Module"
        headerAction={{ icon: <CloseIcon />, title: 'Close', onClick: () => this.props.onClose && this.props.onClose(false), disabled: this.state.loading }} />
    );

  }

}
