import React from 'react';
import { Button } from '@fluentui/react-northstar';
import { Link } from 'react-router-dom';
import { AuthService } from '../../services/AuthService';
import styles from './Header.module.scss';
import logo from '../../images/platyno.svg';
import { Persona, PersonaSize } from '@fluentui/react';
import { AccountInfo } from '@azure/msal-common';

export default class Header extends React.Component {

  public logout = async () => {
    try {
      await AuthService.logout();
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const account: AccountInfo | null = AuthService.getAccount()
    return (
      <div className={styles.Header}>
        {process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT' ? <div className={`${styles.System} ${styles.SystemDevelopment}`}>DEVELOPMENT</div> : null}
        {process.env.REACT_APP_ENVIRONMENT === 'NIGHTLY' ? <div className={`${styles.System} ${styles.SystemNightly}`}>NIGHTLY</div> : null}
        <div className={styles.Logo}>
          <Link to="/"><img src={logo} alt="Platyno Tools Platform" title="Platyno Tools Platform" /></Link>
        </div>
        <React.Fragment>
          <div className={styles.Profile}>
            <Persona text={account?.name} secondaryText={account?.username} showSecondaryText={true} size={PersonaSize.size32} />
            <div className="logout">
              <Button primary content="Logout" onClick={this.logout} />
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }

}
