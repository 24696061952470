import React from 'react';
import logo from './images/platyno.svg';
import styles from './App.module.scss';
import { AuthService } from './services/AuthService';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Button, mergeThemes, Provider, teamsTheme } from '@fluentui/react-northstar';

import Header from './components/header/Header';
import Sidebar from './components/sidebar/Sidebar';
import Dashboard from './pages/Dashboard';
import Modules from './pages/modules/Modules';
import Tenants from './pages/tenants/Tenants';

const theme: any = {
  siteVariables: {
    colors: {
      brand: {}
    }
  },
  componentVariables: {
    Button: {
      primaryColor: '#333333',
      primaryColorHover: '#333333',
      primaryBackgroundColor: '#ffb300',
      primaryBackgroundColorActive: '#e5a100',
      primaryBackgroundColorHover: '#e5a100',
    },
  },
}

export default class App extends React.Component {
  public render() {
    return (
      <MsalProvider instance={AuthService.getAuthProvider()}>
        <Provider theme={mergeThemes(teamsTheme, theme)}>
          <AuthenticatedTemplate>
            <BrowserRouter>
              <div className={styles.Layout}>
                <Header />
                <div className={styles.ContentContainer}>
                  <div className={styles.Sidebar}>
                    <Sidebar />
                  </div>
                  <div className={styles.MainContent}>
                    <Routes>
                      <Route path="/modules" Component={Modules} />
                      <Route path="/tenants" Component={Tenants} />
                      <Route path="/" Component={Dashboard} />
                    </Routes>
                  </div>
                </div>
              </div>
            </BrowserRouter>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div className={styles.Unauthenticated}>
              <div className={styles.UnauthenticatedContent}>
                <div><img src={logo} alt="Platyno Tools Platform" title="Platyno Tools Platform" /></div>
                <p>Please login to use our services!</p>
                <Button primary content="Login" onClick={() => AuthService.login()} />
              </div>
            </div>
          </UnauthenticatedTemplate>
        </Provider>
      </MsalProvider >
    );
  }
}
