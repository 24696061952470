import { AccountInfo, AuthenticationResult, BrowserCacheLocation, Configuration, PublicClientApplication } from "@azure/msal-browser";

const msalConfiguration: Configuration = {
  auth: {
    authority: process.env.REACT_APP_MSAL_AUTHORITY || '',
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID || '',
    redirectUri: `${window.location.protocol}//${window.location.host}`,
    postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}/`,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage
  }
};

export class AuthService {

  private static provider: PublicClientApplication;

  public static getAuthProvider(): PublicClientApplication {
    if (!this.provider) {
      this.provider = new PublicClientApplication(msalConfiguration);
    }
    return this.provider;
  }

  public static login(): Promise<void> {
    return AuthService.getAuthProvider().loginRedirect({
      scopes: [process.env.REACT_APP_MSAL_API_SCOPE || '']
    });
  }

  public static logout(): Promise<void> {
    return AuthService.getAuthProvider().logoutRedirect();
  }

  public static async getAccessToken(): Promise<string> {
    try {
      const account: AccountInfo | null = AuthService.getAccount();
      if (account) {
        const token: AuthenticationResult = await AuthService.getAuthProvider().acquireTokenSilent({
          account: account,
          scopes: [process.env.REACT_APP_MSAL_API_SCOPE || '']
        });
        return token.accessToken;
      }
    } catch (error) {
      console.error(error);
    }
    return '';
  }

  public static getAccount(): AccountInfo | null {
    const accounts: AccountInfo[] = AuthService.getAuthProvider().getAllAccounts();
    return accounts && accounts.length > 0 ? accounts[0] : null;
  }

}