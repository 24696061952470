import * as React from 'react';
import Axios from 'axios';
import moment from 'moment';
import { CloseIcon, Dialog, Text } from '@fluentui/react-northstar';
import { GetSolutionDTO } from '../../models/Administration/ModuleController/getSolutionDTO';
import { GetModuleVersionDTO } from '../../models/Public/ModuleController/getModuleVersionDTO';
import { AlertError, IAlertErrorProps } from '../../components/alerts/AlertError';
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, SelectionMode, ShimmeredDetailsList } from '@fluentui/react';

interface IModuleVersionDialogProps {
  onClose?: any;
  module: GetSolutionDTO;
}

interface IModuleVersionDialogState {
  error: IAlertErrorProps | null;
  loading: boolean;
  versions: GetModuleVersionDTO[];
}

export class ModuleVersionDialog extends React.Component<IModuleVersionDialogProps, IModuleVersionDialogState> {

  public state: IModuleVersionDialogState = {
    error: null,
    loading: false,
    versions: [],
  }

  public async componentDidMount() {
    this.getVersionHistory();
  }

  private getVersionHistory = async () => {
    try {
      this.setState({ loading: true, versions: [] });
      const response = await Axios.get(process.env.REACT_APP_API_BASE + '/api/modules/' + this.props.module.id + '/versions');
      this.setState({ loading: false, versions: response.data.versions });
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.errorCode) {
        this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getVersionHistory() } });
      } else {
        this.setState({ loading: false, error: { message: 'Failed to load modules from server!', retry: () => this.getVersionHistory() } });
      }
    }
  }

  public render() {

    const columns: IColumn[] = [{
      key: 'version',
      name: 'Version',
      minWidth: 150,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (version: GetModuleVersionDTO) => {
        return <a href={version.downloadUrl} target="_blank" rel="noreferrer">{version.version}</a>;
      }
    }, {
      key: 'published',
      name: 'Published',
      minWidth: 150,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (version: GetModuleVersionDTO) => {
        return <span>{moment(version.published).format('YYYY-MM-DD HH:mm:ss')}</span>
      }
    }];

    const table = <ShimmeredDetailsList
      columns={columns}
      items={this.state.versions}
      layoutMode={DetailsListLayoutMode.justified}
      selectionMode={SelectionMode.none}
      enableShimmer={this.state.loading} />;

    const content = <React.Fragment>
      <div style={{ marginTop: '6px' }}>
        <Text weight="semibold">Module ID:</Text> {this.props.module.id}<br />
        <Text weight="semibold">Module Name:</Text> {this.props.module.name}
      </div>
      {!this.state.error ? <div style={{ maxHeight: '520px', overflow: 'auto' }}>{table}</div> : null}
      {!this.state.loading && this.state.error ? <div style={{ marginTop: '12px' }}><AlertError {...this.state.error} /></div> : null}
    </React.Fragment>

    return (
      <Dialog
        open={true}
        styles={{ width: '480px' }}
        closeOnOutsideClick={false}
        onCancel={() => this.props.onClose && this.props.onClose(false)}
        cancelButton={{ content: 'Close' }}
        content={content}
        header="Version History"
        headerAction={{ icon: <CloseIcon />, title: 'Close', onClick: () => this.props.onClose && this.props.onClose(false) }} />
    );

  }

}