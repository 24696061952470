import Axios from 'axios';
import React from 'react';
import { CloseIcon, Dialog, Text } from '@fluentui/react-northstar';
import { GetTenantDTO } from '../../models/Administration/TenantController/getTenantDTO';
import { AlertError, IAlertErrorProps } from '../../components/alerts/AlertError';
import { ColumnActionsMode, DatePicker, DayOfWeek, DetailsListLayoutMode, Dropdown, IColumn, IDropdownOption, SelectionMode, ShimmeredDetailsList, SpinButton, Stack, initializeIcons } from '@fluentui/react';
import { SetTenantLicenseSolutionDTO } from '../../models/Administration/LicenseController/setTenantLicenseSolutionDTO';
import { GetSolutionDTO } from '../../models/Administration/ModuleController/getSolutionDTO';
import { LicenseType } from '../../enums/LicenseType';
import { GetTenantLicenseSolutionDTO } from '../../models/Administration/LicenseController/getTenantLicenseSolutionDTO';
import moment from 'moment';

interface ITenantLicenseModel {
  solutionId: string;
  solutionName: string;
  licenseType: any;
  licenseValidFrom: Date;
  licenseValidTo: Date;
  licenseGracePeriod: number;
  licenseCals: number;
}

interface ITenantLicenseDialogProps {
  onClose?: any;
  tenant: GetTenantDTO;
}

interface ITenantLicenseDialogState {
  error: IAlertErrorProps | null;
  loading: boolean;
  saving: boolean;
  modules: GetSolutionDTO[],
  licenses: ITenantLicenseModel[],
}

export class TenantLicenseDialog extends React.Component<ITenantLicenseDialogProps, ITenantLicenseDialogState> {

  public state: ITenantLicenseDialogState = {
    error: null,
    loading: false,
    saving: false,
    modules: [],
    licenses: [],
  }

  public componentDidMount() {
    this.getLicenses();
    initializeIcons();
  }

  public getLicenses = async () => {
    try {
      this.setState({ loading: true, modules: [], licenses: [], error: null });
      const modules = await Axios.get(process.env.REACT_APP_API_BASE + '/api/admin/modules');
      const licenses = await Axios.get(process.env.REACT_APP_API_BASE + '/api/admin/licenses/' + this.props.tenant?.id);
      this.setState({
        loading: false, modules: modules.data, licenses: licenses.data.map((license: GetTenantLicenseSolutionDTO) => {
          return {
            ...license,
            licenseValidFrom: new Date(license.licenseValidFrom),
            licenseValidTo: new Date(license.licenseValidTo),
          }
        })
      });
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.errorCode) {
        this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getLicenses() } });
      } else {
        this.setState({ loading: false, error: { message: 'Failed to load licenses from server!', retry: () => this.getLicenses() } });
      }
    }
  }

  public getRemainingModules = (): IDropdownOption[] => {
    return this.state.modules.filter((module: GetSolutionDTO) => {
      return !this.state.licenses.some((license: ITenantLicenseModel) => license.solutionId === module.id);
    }).map((module: GetSolutionDTO): IDropdownOption => {
      return { key: module.id, text: module.name };
    });
  }

  public onChange = (index: number = 0, field: string, value: any) => {
    const licenses: ITenantLicenseModel[] = [...this.state.licenses];
    const license: any = { ...licenses[index] };
    license[field] = value;
    licenses.splice(index, 1, license);
    this.setState({ licenses: licenses });
  }

  public onAddModule = (module: IDropdownOption) => {
    const licenses: ITenantLicenseModel[] = [...this.state.licenses];
    licenses.push({
      solutionId: module.key.toString(),
      solutionName: module.text,
      licenseType: LicenseType.UNLICENSED,
      licenseValidFrom: new Date(),
      licenseValidTo: new Date(),
      licenseGracePeriod: 14,
      licenseCals: 0,
    });
    this.setState({ licenses: licenses });
  }

  public submitForm = async () => {
    try {
      this.setState({ saving: true, error: null });
      await Axios.post(process.env.REACT_APP_API_BASE + '/api/admin/licenses/' + this.props.tenant?.id, this.state.licenses.map((value: ITenantLicenseModel): SetTenantLicenseSolutionDTO => {
        return {
          solutionId: value.solutionId,
          licenseType: value.licenseType,
          licenseValidFrom: moment(value.licenseValidFrom).format('YYYY-MM-DDT00:00:00Z'),
          licenseValidTo: moment(value.licenseValidTo).format('YYYY-MM-DDT00:00:00Z'),
          licenseGracePeriod: value.licenseGracePeriod,
          licenseCals: value.licenseCals,
        }
      }));
      this.setState({ saving: false });
      this.props.onClose();
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.errorCode) {
        this.setState({ saving: false, error: { message: error.response.data.errorCode, retry: () => this.submitForm() } });
      } else {
        this.setState({ saving: false, error: { message: 'Failed to update tenant licenses!', retry: () => this.submitForm() } });
      }
    }
  }

  render() {

    const licenseTypes: IDropdownOption[] = [
      { key: 0, text: 'Unlicensed' },
      { key: 1, text: 'Trial' },
      { key: 2, text: 'Purchase' },
      { key: 3, text: 'Subscription' },
      { key: 4, text: 'Community' },
    ];

    const columns: IColumn[] = [{
      key: 'solutionName',
      name: 'Module',
      minWidth: 280,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (license: ITenantLicenseModel) => {
        return <Text size="medium">{license.solutionName}</Text>;
      }
    }, {
      key: 'licenseType',
      name: 'License Type',
      minWidth: 160,
      maxWidth: 160,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (license: ITenantLicenseModel, index?: number) => {
        return <Stack><Dropdown options={licenseTypes} styles={{ dropdown: { width: 160 } }} selectedKey={license.licenseType}
          onChange={(evt: any, option: any) => this.onChange(index, 'licenseType', option.key)} disabled={this.state.saving} /></Stack>;
      }
    }, {
      key: 'validFrom',
      name: 'Valid (from)',
      minWidth: 160,
      maxWidth: 160,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (license: ITenantLicenseModel, index?: number) => {
        return <Stack><DatePicker firstDayOfWeek={DayOfWeek.Monday} placeholder="Select a date..." disabled={this.state.saving}
          allowTextInput={true} formatDate={(date?: Date) => date ? moment(date).format('YYYY-MM-DD') : ''} value={license.licenseValidFrom}
          onSelectDate={(date: any) => this.onChange(index, 'licenseValidFrom', date)} /></Stack>;
      }
    }, {
      key: 'validTo',
      name: 'Valid (to)',
      minWidth: 160,
      maxWidth: 160,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (license: ITenantLicenseModel, index?: number) => {
        return <Stack><DatePicker firstDayOfWeek={DayOfWeek.Monday} placeholder="Select a date..." disabled={this.state.saving}
          allowTextInput={true} formatDate={(date?: Date) => date ? moment(date).format('YYYY-MM-DD') : ''} value={license.licenseValidTo}
          onSelectDate={(date: any) => this.onChange(index, 'licenseValidTo', date)} /></Stack>;
      }
    }, {
      key: 'gracep',
      name: 'Grace Period',
      minWidth: 100,
      maxWidth: 100,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (license: ITenantLicenseModel, index?: number) => {
        return <Stack><SpinButton min={0} step={1} value={license.licenseGracePeriod?.toString()} disabled={this.state.saving}
          onChange={(evt: any, value: any) => this.onChange(index, 'licenseGracePeriod', value)} /></Stack>;
      }
    }, {
      key: 'cals',
      name: 'CALs',
      minWidth: 100,
      maxWidth: 100,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (license: ITenantLicenseModel, index?: number) => {
        return <Stack><SpinButton min={0} step={1} value={license.licenseCals?.toString()} disabled={this.state.saving}
          onChange={(evt: any, value: any) => this.onChange(index, 'licenseCals', value)} /></Stack>;
      }
    }];

    const table = <ShimmeredDetailsList
      columns={columns}
      items={this.state.licenses}
      layoutMode={DetailsListLayoutMode.justified}
      selectionMode={SelectionMode.none}
      enableShimmer={this.state.loading} />;

    const getRemainingModules = this.getRemainingModules();

    const content = <React.Fragment>
      {!this.state.loading && this.state.error ? <div style={{ marginTop: '6px' }}><AlertError {...this.state.error} /></div> : null}
      {table}
      {!this.state.loading && <div style={{ marginTop: '12px' }}>
        <Dropdown options={getRemainingModules} styles={{ dropdown: { width: 300 } }} placeholder="Select additional modules..."
          selectedKey={null} onChange={(evt: any, option: any) => this.onAddModule(option)}
          disabled={this.state.saving || getRemainingModules.length === 0} />
      </div>}
    </React.Fragment>;

    return (
      <Dialog
        open={true}
        styles={{ width: '1200px' }}
        closeOnOutsideClick={false}
        onCancel={() => this.props.onClose && this.props.onClose(false)}
        onConfirm={this.submitForm}
        cancelButton={{ content: 'Cancel', disabled: this.state.saving }}
        confirmButton={{ content: 'Confirm', primary: true, disabled: this.state.saving, loading: this.state.saving }}
        content={content}
        header="Manage Tenant Licenses"
        headerAction={{ icon: <CloseIcon />, title: 'Close', onClick: () => this.props.onClose && this.props.onClose(false), disabled: this.state.saving }} />
    );

  }

}
