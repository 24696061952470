import * as React from 'react';
import Axios from 'axios';
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, SelectionMode, ShimmeredDetailsList } from '@fluentui/react';
import { Button, Flex, Input, SearchIcon } from '@fluentui/react-northstar';
import { AlertError, IAlertErrorProps } from '../../components/alerts/AlertError';
import PlusIcon from 'mdi-react/PlusIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import ReloadIcon from 'mdi-react/ReloadIcon';
import LicenseIcon from 'mdi-react/LicenseIcon';
import ChartBoxOutlineIcon from 'mdi-react/ChartBoxOutlineIcon';
import { TenantCreateDialog } from './TenantCreateDialog';
import { TenantEditDialog } from './TenantEditDialog';
import { TenantDeleteDialog } from './TenantDeleteDialog';
import { GetTenantDTO } from '../../models/Administration/TenantController/getTenantDTO';
import { TenantLicenseDialog } from './TenantLicenseDialog';
import { TenantStatisticsDialog } from './TenantStatisticsDialog';

interface ITenantsProps {

}

interface ITenantsState {
  error: IAlertErrorProps | null;
  loading: boolean;
  tenants: GetTenantDTO[];
  openCreateTenantDialog: boolean;
  openEditTenantDialog: boolean;
  openDeleteTenantDialog: boolean;
  openTenantLicenseDialog: boolean;
  openTenantStatisticsDialog: boolean;
  selectedTenant: GetTenantDTO | null;
  searchInput: string;
  items: GetTenantDTO[],
}

export default class Tenants extends React.Component<ITenantsProps, ITenantsState> {

  public state: ITenantsState = {
    error: null,
    loading: false,
    tenants: [],
    openCreateTenantDialog: false,
    openEditTenantDialog: false,
    openDeleteTenantDialog: false,
    openTenantLicenseDialog: false,
    openTenantStatisticsDialog: false,
    selectedTenant: null,
    searchInput: '',
    items: [],
  };

  public componentDidMount() {
    this.getTenants();
  }

  private getTenants = async () => {
    try {
      this.setState({ loading: true, tenants: [], items: [], error: null });
      const response = await Axios.get(process.env.REACT_APP_API_BASE + '/api/admin/tenants');
      this.setState({ loading: false, tenants: response.data, items: response.data });
      this.onSearch(this.state.searchInput);
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.errorCode) {
        this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getTenants() } });
      } else {
        this.setState({ loading: false, error: { message: 'Failed to load tenants from server!', retry: () => this.getTenants() } });
      }
    }
  }

  private openCreateTenantDialog = () => {
    this.setState({ openCreateTenantDialog: true });
  }

  public closeCreateTenantDialog = (refresh: boolean) => {
    this.setState({ openCreateTenantDialog: false });
    if (refresh) this.getTenants();
  }

  private openEditTenantDialog = (tenant: GetTenantDTO) => {
    this.setState({ openEditTenantDialog: true, selectedTenant: tenant });
  }

  public closeEditTenantDialog = (refresh: boolean) => {
    this.setState({ openEditTenantDialog: false, selectedTenant: null });
    if (refresh) this.getTenants();
  }

  private openDeleteTenantDialog = (tenant: GetTenantDTO) => {
    this.setState({ openDeleteTenantDialog: true, selectedTenant: tenant });
  }

  public closeDeleteTenantDialog = (refresh: boolean) => {
    this.setState({ openDeleteTenantDialog: false, selectedTenant: null });
    if (refresh) this.getTenants();
  }

  private openTenantLicenseDialog = (tenant: GetTenantDTO) => {
    this.setState({ openTenantLicenseDialog: true, selectedTenant: tenant });
  }

  public closeTenantLicenseDialog = (refresh: boolean) => {
    this.setState({ openTenantLicenseDialog: false, selectedTenant: null });
  }

  private openTenantStatisticsDialog = (tenant: GetTenantDTO) => {
    this.setState({ openTenantStatisticsDialog: true, selectedTenant: tenant });
  }

  public closeTenantStatisticsDialog = (refresh: boolean) => {
    this.setState({ openTenantStatisticsDialog: false, selectedTenant: null });
  }

  private onSearch = (searchInput: string) => {
    const searchRegex: RegExp = new RegExp('.*' + (searchInput || '').replace(/\s+/gi, '.*') + '.*', 'gi');
    const items = this.state.tenants.filter((tenant: GetTenantDTO) => tenant.id?.match(searchRegex) || tenant.domain?.match(searchRegex) || tenant.displayName?.match(searchRegex));
    this.setState({ searchInput: searchInput, items: items });
  }

  public render() {

    const columns: IColumn[] = [{
      key: 'id',
      name: 'ID',
      fieldName: 'id',
      minWidth: 280,
      maxWidth: 280,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
    }, {
      key: 'domain',
      name: 'Domain',
      fieldName: 'domain',
      minWidth: 280,
      maxWidth: 280,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true
    }, {
      key: 'displayName',
      name: 'Display Name',
      fieldName: 'displayName',
      minWidth: 300,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true
    }, {
      key: 'actions',
      name: 'Actions',
      minWidth: 640,
      maxWidth: 620,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (tenant: GetTenantDTO) => {
        return <Flex gap="gap.small">
          <Button content="Edit Tenant" icon={<PencilIcon />} iconPosition="before" onClick={() => this.openEditTenantDialog(tenant)} />
          <Button content="Manage Licenses" icon={<LicenseIcon />} iconPosition="before" onClick={() => this.openTenantLicenseDialog(tenant)} />
          <Button content="Statistics" icon={<ChartBoxOutlineIcon />} iconPosition="before" onClick={() => this.openTenantStatisticsDialog(tenant)} />
          <Button content="Delete Tenant" icon={<DeleteIcon />} iconPosition="before" onClick={() => this.openDeleteTenantDialog(tenant)} />
        </Flex>;
      }
    }];

    const table = <ShimmeredDetailsList
      columns={columns}
      items={this.state.items}
      layoutMode={DetailsListLayoutMode.justified}
      selectionMode={SelectionMode.none}
      enableShimmer={this.state.loading} />;

    return (
      <React.Fragment>
        <h1>Tenants</h1>
        <Flex space="between">
          <Flex gap="gap.small">
            <Button content="Create Tenant" icon={<PlusIcon />} iconPosition="before" onClick={this.openCreateTenantDialog} />
            <Button content="Refresh" icon={<ReloadIcon />} iconPosition="before" onClick={this.getTenants} />
          </Flex>
          <div style={{ width: '280px' }}>
            {!this.state.loading && !this.state.error ? <Input fluid={true} clearable={true} autoComplete="off" autoCorrect="off" icon={<SearchIcon />} placeholder="Search..."
              value={this.state.searchInput} onChange={(evt: any) => this.onSearch(evt.target?.value)} /> : null}
          </div>
        </Flex>
        {!this.state.error ? table : null}
        {!this.state.loading && this.state.error ? <div style={{ marginTop: '12px' }}><AlertError {...this.state.error} /></div> : null}
        {this.state.openCreateTenantDialog ? <TenantCreateDialog onClose={this.closeCreateTenantDialog} /> : null}
        {this.state.openEditTenantDialog && this.state.selectedTenant && <TenantEditDialog onClose={this.closeEditTenantDialog} tenant={this.state.selectedTenant} />}
        {this.state.openDeleteTenantDialog && this.state.selectedTenant && <TenantDeleteDialog onClose={this.closeDeleteTenantDialog} tenant={this.state.selectedTenant} />}
        {this.state.openTenantLicenseDialog && this.state.selectedTenant && <TenantLicenseDialog onClose={this.closeTenantLicenseDialog} tenant={this.state.selectedTenant} />}
        {this.state.openTenantStatisticsDialog && this.state.selectedTenant && <TenantStatisticsDialog onClose={this.closeTenantStatisticsDialog} tenant={this.state.selectedTenant} />}
      </React.Fragment>
    )
  }

}