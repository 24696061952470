import * as React from 'react';
import Axios from 'axios';
import moment from 'moment';
import { Flex, Loader } from '@fluentui/react-northstar';
import { GetModuleLatestVersionDTO } from '../../models/Public/ModuleController/getModuleLatestVersionDTO';

interface IModuleVersionLatestProps {
  solutionId: string;
}

interface IModuleVersionLatestState {
  loading: boolean;
  version: GetModuleLatestVersionDTO | null;
}

export class ModuleVersionLatest extends React.Component<IModuleVersionLatestProps, IModuleVersionLatestState> {

  public state: IModuleVersionLatestState = {
    loading: true,
    version: null,
  };

  public async componentDidMount() {
    try {
      const response = await Axios.get(process.env.REACT_APP_API_BASE + '/api/modules/' + this.props.solutionId + '/latest');
      this.setState({ loading: false, version: response.data });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  public render() {
    return this.state.loading
      ? <Flex><Loader size="smallest" /></Flex>
      : (this.state.version ? <span><a href={this.state.version.downloadUrl} target="_blank" rel="noreferrer">{this.state.version.version}</a><br />{moment(this.state.version.published).format('YYYY-MM-DD HH:mm:ss')}</span> : <span>n/a</span>);
  }

}