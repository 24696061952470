import { AuthService } from '../services/AuthService';
import Axios from 'axios';

Axios.interceptors.request.use(async (config) => {
  const accessToken: string = await AuthService.getAccessToken();
  if (accessToken && accessToken.length > 0) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
