import Axios from 'axios';
import React from 'react';
import validate from 'validate.js';
import { Alert, Checkbox, CloseIcon, Dialog, Input } from '@fluentui/react-northstar';
import { FormValidationMessages } from '../../components/formValidation/FormValidationMessages';
import { CreateSolutionDTO } from '../../models/Administration/ModuleController/createSolutionDTO';
import { Dropdown, IDropdownOption } from '@fluentui/react';

interface IModuleCreateDialogProps {
  onClose?: any;
}

interface IModuleCreateDialogState {
  error: string | null;
  saving: boolean;
  formData: CreateSolutionDTO;
  formValidation?: {
    id?: string[];
    name?: string[];
    type?: string[];
  };
}

export class ModuleCreateDialog extends React.Component<IModuleCreateDialogProps, IModuleCreateDialogState> {

  public state: IModuleCreateDialogState = {
    error: null,
    saving: false,
    formData: { type: 0 } as CreateSolutionDTO,
    formValidation: {},
  }

  public onChange = (field: string, value: any) => {
    const formData: any = { ...this.state.formData };
    formData[field] = value;
    this.setState({ formData: formData });
  }

  public submitForm = async () => {

    const errors = validate(this.state.formData, {
      id: {
        presence: { allowEmpty: false, message: 'Please provide a valid GUID for this module!' },
        format: {
          pattern: "^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?",
          flags: "i",
          message: 'Please provide a valid GUID for this module!'
        }
      },
      name: {
        presence: { allowEmpty: false, message: 'Please provide a name for the module!' },
      },
    });

    this.setState({ formValidation: errors });

    if (!errors) {
      try {
        this.setState({ saving: true, error: null });
        await Axios.post(process.env.REACT_APP_API_BASE + '/api/admin/modules', this.state.formData);
        this.setState({ saving: false });
        this.props.onClose(true);
      } catch (error: any) {
        console.error(error);
        if (error?.response?.data?.errorCode) {
          this.setState({ saving: false, error: error.response.data.errorCode });
        } else {
          this.setState({ saving: false, error: 'Failed to create the module!' });
        }
      }
    }

  }

  render() {

    const solutionTypes: IDropdownOption[] = [
      { key: 0, text: 'Premium' },
      { key: 1, text: 'Community' },
    ];

    const content = (
      <React.Fragment>
        {!this.state.saving && this.state.error ? <Alert danger={true} content={this.state.error} /> : null}
        <div style={{ marginTop: '12px' }}>
          <Input label="Module ID" required={true} fluid={true}
            value={this.state.formData.id} disabled={this.state.saving}
            showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('id', evt.target?.value) }}
            error={this.state.formValidation?.id && this.state.formValidation?.id.length > 0} />
          <FormValidationMessages messages={this.state.formValidation?.id} />
        </div>
        <div style={{ marginTop: '12px' }}>
          <Input label="Module Name" required={true} fluid={true}
            value={this.state.formData.name} disabled={this.state.saving}
            showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('name', evt.target?.value) }}
            error={this.state.formValidation?.name && this.state.formValidation?.name.length > 0} />
          <FormValidationMessages messages={this.state.formValidation?.name} />
        </div>
        <div style={{ marginTop: '12px' }}>
          <Dropdown label="Module Type" options={solutionTypes} style={{ width: '100%' }} disabled={this.state.saving}
            selectedKey={this.state.formData.type} onChange={(evt: any, option: any) => this.onChange('type', option.key)} />
        </div>
        <div style={{ marginTop: '12px' }}>
          <Checkbox label="Module Public (One-Click Installer)" disabled={this.state.saving} toggle
            checked={this.state.formData.isPublic} onChange={(evt, data) => this.onChange('isPublic', data?.checked)} />
        </div>
      </React.Fragment>
    );

    return (
      <Dialog
        open={true}
        styles={{ width: '420px' }}
        closeOnOutsideClick={false}
        onCancel={() => this.props.onClose && this.props.onClose(false)}
        onConfirm={this.submitForm}
        cancelButton={{ content: 'Cancel', disabled: this.state.saving }}
        confirmButton={{ content: 'Confirm', primary: true, disabled: this.state.saving, loading: this.state.saving }}
        content={content}
        header="Create Module"
        headerAction={{ icon: <CloseIcon />, title: 'Close', onClick: () => this.props.onClose && this.props.onClose(false), disabled: this.state.saving }} />
    );

  }

}
