import Axios from 'axios';
import React from 'react';
import { CloseIcon, Dialog } from '@fluentui/react-northstar';
import { GetTenantDTO } from '../../models/Administration/TenantController/getTenantDTO';
import { AlertError, IAlertErrorProps } from '../../components/alerts/AlertError';
import { GetUsageDTO } from '../../models/Public/UsageController/getUsageDTO';
import { Spinner } from '@fluentui/react';

interface ITenantStatisticsDialogProps {
  onClose?: any;
  tenant: GetTenantDTO;
}

interface ITenantStatisticsDialogState {
  error: IAlertErrorProps | null;
  loading: boolean;
  usage: GetUsageDTO | null,
}

export class TenantStatisticsDialog extends React.Component<ITenantStatisticsDialogProps, ITenantStatisticsDialogState> {

  public state: ITenantStatisticsDialogState = {
    error: null,
    loading: false,
    usage: null,
  }

  public componentDidMount() {
    this.getUsage();
  }

  public getUsage = async () => {
    try {
      this.setState({ loading: true, usage: null, error: null });
      const response = await Axios.get(process.env.REACT_APP_API_BASE + '/api/usage/' + this.props.tenant?.id);
      this.setState({ loading: false, usage: response.data });
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.errorCode) {
        this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getUsage() } });
      } else {
        this.setState({ loading: false, error: { message: 'Failed to load statistics from server!', retry: () => this.getUsage() } });
      }
    }
  }

  render() {

    const content = <React.Fragment>
      {this.state.loading && <div style={{ paddingTop: '20px' }}><Spinner /></div>}
      {!this.state.loading && this.state.error ? <div style={{ marginTop: '6px' }}><AlertError {...this.state.error} /></div> : null}
      {!this.state.loading && this.state.usage ? <div style={{ marginTop: '6px' }}>
        <p>
          <strong>Tenant ID:</strong> {this.props.tenant.id}<br />
          <strong>Tenant Name:</strong> {this.props.tenant.displayName}<br />
          <strong>Tenant Domain:</strong> {this.props.tenant.domain}
        </p>
        <p style={{ marginBottom: '0px' }}>
          <strong>Unique Users (last 30 days):</strong> {this.state.usage.uniqueUsersLastMonth} users
        </p>
      </div> : null}
    </React.Fragment>;

    return (
      <Dialog
        open={true}
        styles={{ width: '480px' }}
        closeOnOutsideClick={false}
        onCancel={() => this.props.onClose && this.props.onClose(false)}
        cancelButton={{ content: 'Close' }}
        content={content}
        header="Tenant Statistics"
        headerAction={{ icon: <CloseIcon />, title: 'Close', onClick: () => this.props.onClose && this.props.onClose(false) }} />
    );

  }

}
