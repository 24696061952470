import Axios from 'axios';
import React from 'react';
import validate from 'validate.js';
import { Alert, CloseIcon, Dialog, Input } from '@fluentui/react-northstar';
import { FormValidationMessages } from '../../components/formValidation/FormValidationMessages';
import { CreateTenantDTO } from '../../models/Administration/TenantController/createTenantDTO';

interface ITenantCreateDialogProps {
  onClose?: any;
}

interface ITenantCreateDialogState {
  error: string | null;
  saving: boolean;
  formData: CreateTenantDTO;
  formValidation?: {
    id?: string[];
    domain?: string[];
    displayName?: string[];
  };
}

export class TenantCreateDialog extends React.Component<ITenantCreateDialogProps, ITenantCreateDialogState> {

  public state: ITenantCreateDialogState = {
    error: null,
    saving: false,
    formData: {} as CreateTenantDTO,
    formValidation: {},
  }

  public onChange = (field: string, value: any) => {
    const formData: any = { ...this.state.formData };
    formData[field] = value;
    this.setState({ formData: formData });
  }

  public submitForm = async () => {

    const errors = validate(this.state.formData, {
      id: {
        presence: { allowEmpty: false, message: 'Please provide a valid GUID for this tenant!' },
        format: {
          pattern: "^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?",
          flags: "i",
          message: 'Please provide a valid GUID for this tenant!'
        }
      },
      domain: {
        presence: { allowEmpty: false, message: 'Please provide a domain for the tenant!' },
      },
      displayName: {
        presence: { allowEmpty: false, message: 'Please provide a display name for the tenant!' },
      },
    });

    this.setState({ formValidation: errors });

    if (!errors) {
      try {
        this.setState({ saving: true, error: null });
        await Axios.post(process.env.REACT_APP_API_BASE + '/api/admin/tenants', this.state.formData);
        this.setState({ saving: false });
        this.props.onClose(true);
      } catch (error: any) {
        console.error(error);
        if (error?.response?.data?.errorCode) {
          this.setState({ saving: false, error: error.response.data.errorCode });
        } else {
          this.setState({ saving: false, error: 'Failed to create the tenant!' });
        }
      }
    }

  }

  render() {

    const content = (
      <React.Fragment>
        {!this.state.saving && this.state.error ? <Alert danger={true} content={this.state.error} /> : null}
          <div style={{ marginTop: '12px' }}>
            <Input label="Tenant ID" required={true} fluid={true}
              value={this.state.formData.id} disabled={this.state.saving}
              showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('id', evt.target?.value) }}
              error={this.state.formValidation?.id && this.state.formValidation?.id.length > 0} />
            <FormValidationMessages messages={this.state.formValidation?.id} />
          </div>
          <div style={{ marginTop: '12px' }}>
            <Input label="Tenant Domain" required={true} fluid={true}
              value={this.state.formData.domain} disabled={this.state.saving}
              showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('domain', evt.target?.value) }}
              error={this.state.formValidation?.domain && this.state.formValidation?.domain.length > 0} />
            <FormValidationMessages messages={this.state.formValidation?.domain} />
          </div>
          <div style={{ marginTop: '12px' }}>
            <Input label="Tenant Display Name" required={true} fluid={true}
              value={this.state.formData.displayName} disabled={this.state.saving}
              showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('displayName', evt.target?.value) }}
              error={this.state.formValidation?.displayName && this.state.formValidation?.displayName.length > 0} />
            <FormValidationMessages messages={this.state.formValidation?.displayName} />
          </div>
      </React.Fragment>
    );

    return (
      <Dialog
        open={true}
        styles={{ width: '420px' }}
        closeOnOutsideClick={false}
        onCancel={() => this.props.onClose && this.props.onClose(false)}
        onConfirm={this.submitForm}
        cancelButton={{ content: 'Cancel', disabled: this.state.saving }}
        confirmButton={{ content: 'Confirm', primary: true, disabled: this.state.saving, loading: this.state.saving }}
        content={content}
        header="Create Tenant"
        headerAction={{ icon: <CloseIcon />, title: 'Close', onClick: () => this.props.onClose && this.props.onClose(false), disabled: this.state.saving }} />
    );

  }

}
