import Axios from 'axios';
import React from 'react';
import validate from 'validate.js';
import { Alert, Checkbox, CloseIcon, Dialog, Input } from '@fluentui/react-northstar';
import { GetSolutionDTO } from '../../models/Administration/ModuleController/getSolutionDTO';
import { UpdateSolutionDTO } from '../../models/Administration/ModuleController/updateSolutionDTO';
import { FormValidationMessages } from '../../components/formValidation/FormValidationMessages';
import { Dropdown, IDropdownOption } from '@fluentui/react';

interface IModuleEditDialogProps {
  onClose?: any;
  module: GetSolutionDTO;
}

interface IModuleEditDialogState {
  error: string | null;
  saving: boolean;
  formData: UpdateSolutionDTO;
  formValidation?: {
    name?: string[];
  };
}

export class ModuleEditDialog extends React.Component<IModuleEditDialogProps, IModuleEditDialogState> {

  public state: IModuleEditDialogState = {
    error: null,
    saving: false,
    formData: {} as UpdateSolutionDTO,
    formValidation: {},
  }

  public async componentDidMount() {
    if (this.props.module) {
      this.setState({ formData: { ...this.props.module } })
    }
  }

  public onChange = (field: string, value: any) => {
    const formData: any = { ...this.state.formData };
    formData[field] = value;
    this.setState({ formData: formData });
  }

  public submitForm = async () => {

    const errors = validate(this.state.formData, {
      name: {
        presence: { allowEmpty: false, message: 'Please provide a name for the module!' },
      },
    });

    this.setState({ formValidation: errors });

    if (!errors) {
      try {
        this.setState({ saving: true, error: null });
        await Axios.patch(process.env.REACT_APP_API_BASE + '/api/admin/modules/' + this.props.module?.id, this.state.formData);
        this.setState({ saving: false });
        this.props.onClose(true);
      } catch (error: any) {
        console.error(error);
        if (error?.response?.data?.errorCode) {
          this.setState({ saving: false, error: error.response.data.errorCode });
        } else {
          this.setState({ saving: false, error: 'Failed to update the module!' });
        }
      }
    }

  }

  render() {

    const solutionTypes: IDropdownOption[] = [
      { key: 0, text: 'Premium' },
      { key: 1, text: 'Community' },
    ];

    const content = (
      <React.Fragment>
        {!this.state.saving && this.state.error ? <Alert danger={true} content={this.state.error} /> : null}
        <div style={{ marginTop: '12px' }}>
          <Input label="Module ID" fluid={true} value={this.props.module?.id} disabled={true} />
        </div>
        <div style={{ marginTop: '12px' }}>
          <Input label="Module Name" required={true} fluid={true}
            value={this.state.formData.name} disabled={this.state.saving}
            showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('name', evt.target?.value) }}
            error={this.state.formValidation?.name && this.state.formValidation?.name.length > 0} />
          <FormValidationMessages messages={this.state.formValidation?.name} />
        </div>
        <div style={{ marginTop: '12px' }}>
          <Dropdown label="Module Type" options={solutionTypes} disabled={this.state.saving}
            selectedKey={this.state.formData.type} onChange={(evt: any, option: any) => this.onChange('type', option.key)} />
        </div>
        <div style={{ marginTop: '12px' }}>
          <Checkbox label="Module Public (One-Click Installer)" disabled={this.state.saving} toggle
            checked={this.state.formData.isPublic} onChange={(evt, data) => this.onChange('isPublic', data?.checked)} />
        </div>
      </React.Fragment>
    );

    return (
      <Dialog
        open={true}
        styles={{ width: '420px' }}
        closeOnOutsideClick={false}
        onCancel={() => this.props.onClose && this.props.onClose(false)}
        onConfirm={this.submitForm}
        cancelButton={{ content: 'Cancel', disabled: this.state.saving }}
        confirmButton={{ content: 'Confirm', primary: true, disabled: this.state.saving, loading: this.state.saving }}
        content={content}
        header="Edit Module"
        headerAction={{ icon: <CloseIcon />, title: 'Close', onClick: () => this.props.onClose && this.props.onClose(false), disabled: this.state.saving }} />
    );

  }

}
