import * as React from 'react';

export default class Dashboard extends React.Component {

  public render() {
    return (
      <React.Fragment>
        <h1>Dashboard</h1>
        <p>There is nothing to see here!</p>
      </React.Fragment>
    )
  }

}