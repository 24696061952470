import React from 'react';
import styles from './Sidebar.module.scss';
import { NavLink } from 'react-router-dom';
import MonitorDashboardIcon from 'mdi-react/MonitorDashboardIcon';
import ViewGridIcon from 'mdi-react/ViewGridIcon';
import MicrosoftSharepointIcon from 'mdi-react/MicrosoftSharepointIcon';

export default class Sidebar extends React.Component {
  render() {
    return (
      <div className={styles.Sidebar}>
        <div className={styles.SidebarNavigation}>
          <ul>
            <li><NavLink to="/"><MonitorDashboardIcon />Dashboard</NavLink ></li>
            <li><NavLink to="/modules"><ViewGridIcon />Modules</NavLink ></li>
            <li><NavLink to="/tenants"><MicrosoftSharepointIcon />Tenants</NavLink ></li>
          </ul>
        </div>
        <div className={styles.SidebarFooter}></div>
        <div className={styles.SidebarVersion}>{process.env.REACT_APP_VERSION}</div>
      </div>
    );
  }
}
