import * as React from 'react';
import Axios from 'axios';
import { GetSolutionDTO } from '../../models/Administration/ModuleController/getSolutionDTO';
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, SelectionMode, ShimmeredDetailsList } from '@fluentui/react';
import { Button, Flex, Input, Label, SearchIcon } from '@fluentui/react-northstar';
import { AlertError, IAlertErrorProps } from '../../components/alerts/AlertError';
import PlusIcon from 'mdi-react/PlusIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import ReloadIcon from 'mdi-react/ReloadIcon';
import HistoryIcon from 'mdi-react/HistoryIcon';
import { ModuleCreateDialog } from './ModuleCreateDialog';
import { ModuleEditDialog } from './ModuleEditDialog';
import { ModuleDeleteDialog } from './ModuleDeleteDialog';
import { ModuleVersionLatest } from './ModuleVersionLatest';
import { ModuleVersionDialog } from './ModuleVersionDialog';

interface IModulesProps {

}

interface IModulesState {
  error: IAlertErrorProps | null;
  loading: boolean;
  modules: GetSolutionDTO[];
  openCreateModuleDialog: boolean;
  openEditModuleDialog: boolean;
  openDeleteModuleDialog: boolean;
  openModuleVersionDialog: boolean;
  selectedModule: GetSolutionDTO | null;
  searchInput: string;
  items: GetSolutionDTO[],
}

export default class Modules extends React.Component<IModulesProps, IModulesState> {

  public state: IModulesState = {
    error: null,
    loading: false,
    modules: [],
    openCreateModuleDialog: false,
    openEditModuleDialog: false,
    openDeleteModuleDialog: false,
    openModuleVersionDialog: false,
    selectedModule: null,
    searchInput: '',
    items: [],
  };

  public componentDidMount() {
    this.getModules();
  }

  private getModules = async () => {
    try {
      this.setState({ loading: true, modules: [], items: [], error: null });
      const response = await Axios.get(process.env.REACT_APP_API_BASE + '/api/admin/modules');
      this.setState({ loading: false, modules: response.data, items: response.data });
      this.onSearch(this.state.searchInput);
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.errorCode) {
        this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getModules() } });
      } else {
        this.setState({ loading: false, error: { message: 'Failed to load modules from server!', retry: () => this.getModules() } });
      }
    }
  }

  private openCreateModuleDialog = () => {
    this.setState({ openCreateModuleDialog: true });
  }

  public closeCreateModuleDialog = (refresh: boolean) => {
    this.setState({ openCreateModuleDialog: false });
    if (refresh) this.getModules();
  }

  private openEditModuleDialog = (module: GetSolutionDTO) => {
    this.setState({ openEditModuleDialog: true, selectedModule: module });
  }

  public closeEditModuleDialog = (refresh: boolean) => {
    this.setState({ openEditModuleDialog: false, selectedModule: null });
    if (refresh) this.getModules();
  }

  private openDeleteModuleDialog = (module: GetSolutionDTO) => {
    this.setState({ openDeleteModuleDialog: true, selectedModule: module });
  }

  public closeDeleteModuleDialog = (refresh: boolean) => {
    this.setState({ openDeleteModuleDialog: false, selectedModule: null });
    if (refresh) this.getModules();
  }

  private openModuleVersionDialog = (module: GetSolutionDTO) => {
    this.setState({ openModuleVersionDialog: true, selectedModule: module });
  }

  public closeModuleVersionDialog = (refresh: boolean) => {
    this.setState({ openModuleVersionDialog: false, selectedModule: null });
  }

  private onSearch = (searchInput: string) => {
    const searchRegex: RegExp = new RegExp('.*' + (searchInput || '').replace(/\s+/gi, '.*') + '.*', 'gi');
    const items = this.state.modules.filter((module: GetSolutionDTO) => module.id?.match(searchRegex) || module.name?.match(searchRegex));
    this.setState({ searchInput: searchInput, items: items });
  }

  public render() {

    const columns: IColumn[] = [{
      key: 'id',
      name: 'ID',
      fieldName: 'id',
      minWidth: 280,
      maxWidth: 280,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
    }, {
      key: 'name',
      name: 'Name',
      fieldName: 'name',
      minWidth: 300,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (solution: GetSolutionDTO) => {
        return <span>{solution.name} <Label color={solution.isPublic ? "green" : "orange"} content={solution.isPublic ? "PUBLIC" : "PRIVATE"} /></span>
      }
    }, {
      key: 'type',
      name: 'Type',
      minWidth: 120,
      maxWidth: 120,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (solution: GetSolutionDTO) => {
        switch (solution.type) {
          case 0: return 'Premium';
          case 1: return 'Community';
        }
        return 'Undefined';
      }
    }, {
      key: 'version',
      name: 'Latest Version',
      minWidth: 180,
      maxWidth: 180,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (solution: GetSolutionDTO) => {
        return <ModuleVersionLatest solutionId={solution.id} />;
      }
    }, {
      key: 'actions',
      name: 'Actions',
      minWidth: 500,
      maxWidth: 500,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (solution: GetSolutionDTO) => {
        return <Flex gap="gap.small">
          <Button content="Edit Module" icon={<PencilIcon />} iconPosition="before" onClick={() => this.openEditModuleDialog(solution)} />
          <Button content="Version History" icon={<HistoryIcon />} iconPosition="before" onClick={() => this.openModuleVersionDialog(solution)} />
          <Button content="Delete Module" icon={<DeleteIcon />} iconPosition="before" onClick={() => this.openDeleteModuleDialog(solution)} />
        </Flex>;
      }
    }];

    const table = <ShimmeredDetailsList
      columns={columns}
      items={this.state.items}
      layoutMode={DetailsListLayoutMode.justified}
      selectionMode={SelectionMode.none}
      enableShimmer={this.state.loading} />;

    return (
      <React.Fragment>
        <h1>Modules</h1>
        <Flex space="between">
          <Flex gap="gap.small">
            <Button content="Create Module" icon={<PlusIcon />} iconPosition="before" onClick={this.openCreateModuleDialog} />
            <Button content="Refresh" icon={<ReloadIcon />} iconPosition="before" onClick={this.getModules} />
          </Flex>
          <div style={{ width: '280px' }}>
            {!this.state.loading && !this.state.error ? <Input fluid={true} clearable={true} autoComplete="off" autoCorrect="off" icon={<SearchIcon />} placeholder="Search..."
              value={this.state.searchInput} onChange={(evt: any) => this.onSearch(evt.target?.value)} /> : null}
          </div>
        </Flex>
        {!this.state.error ? table : null}
        {!this.state.loading && this.state.error ? <div style={{ marginTop: '12px' }}><AlertError {...this.state.error} /></div> : null}
        {this.state.openCreateModuleDialog ? <ModuleCreateDialog onClose={this.closeCreateModuleDialog} /> : null}
        {this.state.openEditModuleDialog && this.state.selectedModule ? <ModuleEditDialog onClose={this.closeEditModuleDialog} module={this.state.selectedModule} /> : null}
        {this.state.openDeleteModuleDialog && this.state.selectedModule ? <ModuleDeleteDialog onClose={this.closeDeleteModuleDialog} module={this.state.selectedModule} /> : null}
        {this.state.openModuleVersionDialog && this.state.selectedModule ? <ModuleVersionDialog onClose={this.closeModuleVersionDialog} module={this.state.selectedModule} /> : null}
      </React.Fragment>
    )
  }

}