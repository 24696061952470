import Axios from 'axios';
import React from 'react';
import validate from 'validate.js';
import { Alert, CloseIcon, Dialog, Input } from '@fluentui/react-northstar';
import { FormValidationMessages } from '../../components/formValidation/FormValidationMessages';
import { UpdateTenantDTO } from '../../models/Administration/TenantController/updateTenantDTO';
import { GetTenantDTO } from '../../models/Administration/TenantController/getTenantDTO';

interface ITenantEditDialogProps {
  onClose?: any;
  tenant: GetTenantDTO;
}

interface ITenantEditDialogState {
  error: string | null;
  saving: boolean;
  formData: UpdateTenantDTO;
  formValidation?: {
    id?: string[];
    domain?: string[];
    displayName?: string[];
  };
}

export class TenantEditDialog extends React.Component<ITenantEditDialogProps, ITenantEditDialogState> {

  public state: ITenantEditDialogState = {
    error: null,
    saving: false,
    formData: {} as UpdateTenantDTO,
    formValidation: {},
  }

  public async componentDidMount() {
    if (this.props.tenant) {
      this.setState({ formData: { ...this.props.tenant } })
    }
  }

  public onChange = (field: string, value: any) => {
    const formData: any = { ...this.state.formData };
    formData[field] = value;
    this.setState({ formData: formData });
  }

  public submitForm = async () => {

    const errors = validate(this.state.formData, {
      domain: {
        presence: { allowEmpty: false, message: 'Please provide a domain for the tenant!' },
      },
      displayName: {
        presence: { allowEmpty: false, message: 'Please provide a display name for the tenant!' },
      },
    });

    this.setState({ formValidation: errors });

    if (!errors) {
      try {
        this.setState({ saving: true, error: null });
        await Axios.patch(process.env.REACT_APP_API_BASE + '/api/admin/tenants/' + this.props.tenant?.id, this.state.formData);
        this.setState({ saving: false });
        this.props.onClose(true);
      } catch (error: any) {
        console.error(error);
        if (error?.response?.data?.errorCode) {
          this.setState({ saving: false, error: error.response.data.errorCode });
        } else {
          this.setState({ saving: false, error: 'Failed to update the tenant!' });
        }
      }
    }

  }

  render() {

    const content = (
      <React.Fragment>
        {!this.state.saving && this.state.error ? <Alert danger={true} content={this.state.error} /> : null}
        <div style={{ marginTop: '12px' }}>
          <Input label="Tenant ID" fluid={true} value={this.props.tenant?.id} disabled={true} />
        </div>
        <div style={{ marginTop: '12px' }}>
          <Input label="Tenant Domain" required={true} fluid={true}
            value={this.state.formData.domain} disabled={this.state.saving}
            showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('domain', evt.target?.value) }}
            error={this.state.formValidation?.domain && this.state.formValidation?.domain.length > 0} />
          <FormValidationMessages messages={this.state.formValidation?.domain} />
        </div>
        <div style={{ marginTop: '12px' }}>
          <Input label="Tenant Display Name" required={true} fluid={true}
            value={this.state.formData.displayName} disabled={this.state.saving}
            showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('displayName', evt.target?.value) }}
            error={this.state.formValidation?.displayName && this.state.formValidation?.displayName.length > 0} />
          <FormValidationMessages messages={this.state.formValidation?.displayName} />
        </div>
      </React.Fragment>
    );

    return (
      <Dialog
        open={true}
        styles={{ width: '420px' }}
        closeOnOutsideClick={false}
        onCancel={() => this.props.onClose && this.props.onClose(false)}
        onConfirm={this.submitForm}
        cancelButton={{ content: 'Cancel', disabled: this.state.saving }}
        confirmButton={{ content: 'Confirm', primary: true, disabled: this.state.saving, loading: this.state.saving }}
        content={content}
        header="Edit Tenant"
        headerAction={{ icon: <CloseIcon />, title: 'Close', onClick: () => this.props.onClose && this.props.onClose(false), disabled: this.state.saving }} />
    );

  }

}
