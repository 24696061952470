import Axios from 'axios';
import React from 'react';
import { Alert, CloseIcon, Dialog } from '@fluentui/react-northstar';
import { GetTenantDTO } from '../../models/Administration/TenantController/getTenantDTO';

interface ITenantDeleteDialogProps {
  onClose?: any;
  tenant: GetTenantDTO;
}

interface ITenantDeleteDialogState {
  error: string | null;
  loading: boolean;
}

export class TenantDeleteDialog extends React.Component<ITenantDeleteDialogProps, ITenantDeleteDialogState> {

  public state: ITenantDeleteDialogState = {
    error: null,
    loading: false,
  }

  public async componentDidMount() {

  }

  public submitForm = async () => {
    try {
      this.setState({ loading: true, error: null });
      await Axios.delete(process.env.REACT_APP_API_BASE + '/api/admin/tenants/' + this.props.tenant?.id);
      this.setState({ loading: false });
      this.props.onClose(true);
    } catch (error: any) {
      console.error(error);
      if (error?.response?.data?.errorCode) {
        this.setState({ loading: false, error: error.response.data.errorCode });
      } else {
        this.setState({ loading: false, error: 'Failed to delete the tenant!' });
      }
    }
  }

  render() {

    const content = (
      <React.Fragment>
        {!this.state.loading && this.state.error ? <Alert danger={true} content={this.state.error} /> : null}
        <div style={{ marginTop: '6px' }}>Do you really want to remove the tenant <strong>"{this.props.tenant.displayName}"</strong> including all connected licenses for solutions?</div>
      </React.Fragment>
    );

    return (
      <Dialog
        open={true}
        styles={{ width: '420px' }}
        closeOnOutsideClick={false}
        onCancel={() => this.props.onClose && this.props.onClose(false)}
        onConfirm={this.submitForm}
        cancelButton={{ content: 'Cancel', disabled: this.state.loading }}
        confirmButton={{ content: 'Confirm', primary: true, disabled: this.state.loading, loading: this.state.loading }}
        content={content}
        header="Delete Tenant"
        headerAction={{ icon: <CloseIcon />, title: 'Close', onClick: () => this.props.onClose && this.props.onClose(false), disabled: this.state.loading }} />
    );

  }

}
