import React, { PropsWithChildren } from 'react';
import { Alert } from '@fluentui/react-northstar';

const stylesRetry: any = {
  textDecoration: 'none',
  fontWeight: '600',
  cursor: 'pointer',
  marginLeft: '3px',
}

export interface IAlertErrorProps extends PropsWithChildren {
  message?: string;
  retry?: any;
}

export class AlertError extends React.Component<IAlertErrorProps> {
  render() {
    return (
      <Alert danger={true}>
        {this.props.children ? this.props.children : <React.Fragment>
          {this.props.message ? this.props.message : null}
          {this.props.retry ? <span style={stylesRetry} onClick={this.props.retry}>Retry</span> : null}
        </React.Fragment>}
      </Alert>
    )
  }
}
